import { attachShallow, registerApi } from '@zentnet/react-utils'
import merge from 'lodash/merge'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { getActiveTicketCountApi } from '~/client/api/ticket/getActiveTicketCount'
import type { ViewModel } from '~/client/model/header/ViewModel'
import { nanoStorage } from '../nanoStorage'
import { emptyValueModel } from './emptyValueModel'
import { onInitialize } from './handlers/onIntialize'

export const useViewModel = attachShallow(
  create<ViewModel>()(
    persist(
      (set, get) => ({
        ...emptyValueModel,
        onInitialize: onInitialize(set, get),
        onDeinitialize: () => {},
        ...registerApi(
          'getActiveTicketCountApi',
          getActiveTicketCountApi,
          set,
          get,
        ),
      }),
      {
        name: 'headerViewModel',
        storage: createJSONStorage(() => nanoStorage),
        merge: (persistedState, currentState) =>
          merge({}, currentState, persistedState),
      },
    ),
  ),
)
