import { FaShoppingCart } from 'react-icons/fa'
import { useViewModel } from '~/client/application/header/useViewModel'
import { ButtonAnchorRound } from '~/components/Button'

export const HeaderTicketButton: React.FC = () => {
  const count = useViewModel((m) => m.activeTicketCount)

  return (
    <ButtonAnchorRound
      variant="white"
      className="text-2xl relative !p-2"
      href="/me/ticket"
    >
      <img
        src="/images/icon-ticket.svg"
        alt="ticket"
        className="w-[24px] h-[24px]"
      />
      {count !== null && count > 0 && (
        <div className="bg-primary rounded-full text-xs absolute p-[1px] text-center right-[-5px] top-[-5px] min-w-[18px] px-[3px]">
          {count}
        </div>
      )}
      {/*
            <div className="absolute bg-primary rounded text-sm right-0 bottom-[-40px] whitespace-nowrap px-2 py-[1px] flex items-center">
              <div
                className="w-[16px] aspect-[1/cos(30deg)] bg-primary absolute top-[-10px] right-[13px]"
                style={{
                  clipPath: 'polygon(50% 0,100% 100%,0 100%)',
                }}
              ></div>
              <FaExclamationCircle className="mr-1" />
              <p>本日開催予定の公演があります！</p>
            </div>
            */}
    </ButtonAnchorRound>
  )
}
