import { FaShoppingCart } from 'react-icons/fa'
import { ButtonAnchorRound } from '~/components/Button'
// import { useViewModel } from '~/client/application/cart/useViewModel'

export const HeaderCartButton: React.FC = () => {
  // const count = useViewModel(
  //   (m) => m.cart.eventSlotTicketPlans.length + m.cart.goods.length,
  // )

  const count = 0

  return (
    <ButtonAnchorRound
      variant="white"
      className="text-2xl relative !p-2"
      href="/cart/"
    >
      <FaShoppingCart />
      {count > 0 && (
        <div className="bg-primary rounded-full text-xs absolute p-[1px] text-center right-[-5px] top-[-5px] min-w-[18px] px-[3px]">
          {count}
        </div>
      )}
    </ButtonAnchorRound>
  )
}
