import { useEffect } from 'react'
import { FaSignInAlt, FaSpinner, FaUser } from 'react-icons/fa'
import { useAuthModel } from '~/client/application/auth/useViewModel'
import { useViewModel } from '~/client/application/header/useViewModel'
import { ButtonAnchor } from '~/components/Button'
import { HeaderCartButton } from '../HeaderCartButton'
import { HeaderTicketButton } from '../HeaderTicketButton'
import { HeaderUserButton } from '../HeaderUserButton'
import classNames from 'classnames'

export default function HeaderMe() {
  const { me, initialized } = useAuthModel((m) => ({
    me: m.me,
    initialized: m.initialized,
  }))

  const { oninitialize, onDeinitialize } = useViewModel((m) => ({
    oninitialize: m.onInitialize,
    onDeinitialize: m.onDeinitialize,
  }))

  useEffect(() => {
    oninitialize()
    return () => {
      onDeinitialize()
    }
  }, [me])

  return (
    <div className={classNames('w-full sm:w-auto relative')}>
      {!initialized && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-70 block sm:hidden">
          <FaSpinner className="animate-spin w-6 h-6 text-white" />
        </div>
      )}
      <div
        className={classNames('w-full', 'transition-opacity', 'opacity-0', {
          'pointer-events-none': !initialized,
          'animate-fade-in': initialized,
        })}
      >
        {me ? (
          <div className="flex items-center">
            <HeaderUserButton />

            <div className="mr-2">
              <HeaderCartButton />
            </div>
            <div>
              <HeaderTicketButton />
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-center">
            <ButtonAnchor
              variant="white"
              className="mr-2 items-center flex"
              href="/sign-in"
            >
              <FaSignInAlt className="text-xl" />
              <p className="ml-2">ログイン</p>
            </ButtonAnchor>
            <ButtonAnchor
              variant="primary"
              className="mr-2 flex items-center"
              href="/sign-up"
            >
              <FaUser className="text-xl" />
              <p className="ml-2">新規登録</p>
            </ButtonAnchor>

            <HeaderCartButton />
          </div>
        )}
      </div>
    </div>
  )
}
