import { useMe } from '~/client/application/auth/hooks/useMe'
import { useAuthModel } from '~/client/application/auth/useViewModel'

export const HeaderUserButton: React.FC = () => {
  const me = useMe()
  const pointBalance = useAuthModel((m) => m.pointBalance)

  if (!me) {
    return null
  }

  return (
    <a
      href="/me"
      className="flex-1 sm:flex-auto active:opacity-80 px-2 border-2 border-white rounded-full shadow-buttonShadowWhite font-bold text-sm flex mr-2"
    >
      <div className="-ml-2">
        <img
          src="/images/icon-user.svg"
          className="rounded-full overflow-hidden"
        />
      </div>
      <div className="min-w-[120px] pl-2 flex flex-col justify-center">
        <p className="-mb-[4px] max-w-[200px] text-ellipsis overflow-hidden break-keep whitespace-pre">
          {me.displayName}
        </p>
        <p>{pointBalance === null ? '-' : pointBalance} pt</p>
      </div>
    </a>
  )
}
