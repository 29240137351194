import type { ApiDefinition } from '@zentnet/react-utils'

type RequestType = void

type ResponseType = {
  count: number
}

export const getActiveTicketCountApi: ApiDefinition<RequestType, ResponseType> =
  {
    name: 'getActiveTicketCountApi',
    path: '/api/user/ticket/count-active',
    method: 'GET',
  }
