import type { ApplicationFactory } from '@zentnet/react-utils'
import type { ViewModel } from '~/client/model/header/ViewModel'

export const onInitialize: ApplicationFactory<ViewModel, 'onInitialize'> =
  (set, get) => () => {
    const { getActiveTicketCountApi } = get()

    getActiveTicketCountApi.call(undefined).then((res) => {
      if (res.ok) {
        set({
          activeTicketCount: res.data.count,
        })
      }
    })
  }
